$primary-color: white;
$bg: black;

* {
  font-family: "JetBrains Mono", sans-serif;
}

html, body {
  resize: both;
  overflow: auto;
}

body {
  margin: 0;
  padding: 0;
}

.page-container {
  max-width: 960px;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 50px;

  @media only screen and (max-width: 600px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.snackbar {
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: unset;
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    top: unset;
    bottom: 0;
  }
}

h1 {
  text-align: center;
}

.search-container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;

  input {
    width: 300px;
  }

  button {
    margin-left: 5px;
    width: 200px;
  }

  margin-bottom: 75px;

  @media only screen and (max-width: 600px) {
    margin-bottom: 30px;

    input {
      margin: 0 40px;
    }
  }
}

.emoji-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .emoji-item {
    padding: 5px;
    cursor: pointer;
    img {
      width: 50px;
      height: 50px;
    }
  }
}